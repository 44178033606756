import Head from "next/head"
import React from "react"
import styled from "styled-components"

import { distribution, distributionTitle } from "~/config"
import { color, url } from "~/libs/style"

const isPortal = distribution === "portal"

type CustomErrorProps = {
  title: string
}

export const CustomError: React.FC<CustomErrorProps> = ({ title, children }) => {
  return (
    <Wrapper>
      <Head>
        <title>
          {title} - 無料ゲーム|{distributionTitle}
        </title>
      </Head>

      {isPortal && (
        <Header>
          <HeaderText>au</HeaderText>
        </Header>
      )}

      <Body>{children}</Body>

      {isPortal && (
        <Footer>
          <small>©mediba</small>
        </Footer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: ${color.whiteSmoke};
`

const Header = styled.header`
  padding: 14px 0 11px 0;
  background-color: ${color.white};
`

const HeaderText = styled.h1`
  height: 16px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: ${url("/assets/img/logo.svg")} center no-repeat;
  background-size: 41px auto;
`

const Body = styled.div`
  margin: auto 0;
  font-size: 15px;
  line-height: 1.5;
  color: ${color.gray};
  text-align: center;
`

const Footer = styled.footer`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-top: 10px;
  font-size: 12px;
  text-align: center;
  background-color: ${color.white};
`
