// export * from "./sentry"
export type Distribution = "portal" | "top" | "free" | "paid"
type Env = "production" | "staging" | "development" | "local"

const _distributionTitle = {
  portal: "au Webポータル",
  app: "auサービスTOP",
  top: "イージーゲーム",
  paid: "イージーゲーム（人気ガチ）",
  free: "無料ゲーム"
}
const _google = {
  production: {
    portal: {
      gaTrackingId: "UA-39387335-213",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    free: {
      gaTrackingId: "UA-39387335-213",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    paid: {
      gaTrackingId: "UA-39387335-213",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    top: {
      gaTrackingId: "UA-39387335-213",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    }
  },
  staging: {
    portal: {
      gaTrackingId: "UA-112762436-37",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    free: {
      gaTrackingId: "UA-112762436-37",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    paid: {
      gaTrackingId: "UA-112762436-37",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    },
    top: {
      gaTrackingId: "UA-112762436-37",
      gaTrackerName: "kbd",
      optimizeContainerId: "GTM-KZBM57M"
    }
  }
}

const _repro = {
  production: {
    sdkToken: "a481d6ba-d4fe-42bb-80cd-694c7f3d6ebb"
  },
  staging: {
    sdkToken: "692701c9-b164-4963-b7c2-8b92612814fa"
  }
}

export const distribution = process.env.NEXT_PUBLIC_DISTRIBUTION as Distribution
export const env = process.env.NEXT_PUBLIC_APPLICATION_ENV as Env

export const adIds = {
  portal: {
    fixed: {
      id: "1681812322341-0",
      url: "/49720294/auportal_sp_easygame_top_overlay_320x50",
      size: "[320, 50]"
    },
    rectangle: {
      id: "1681812246209-0",
      url: "/49720294/auportal_sp_easygame_top_3_rectangle",
      size: "[[300, 250], [336, 280]]"
    },
    rectMiddle: {
      id: "1681812404902-0",
      url: "/49720294/auportal_sp_easygame_top_2_rectangle",
      size: "[[336, 280], [300, 250]]"
    },
    infeed: 93178,
    banner: {
      id: "1681812009443-0",
      url: "/49720294/auportal_sp_easygame_top_1_320x100",
      size: "[320, 100]"
    },
    ranking: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196100,
      banner: 142100
    },
    list: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196099,
      banner: 142100
    }
  },
  top: {
    fixed: {
      id: "1681812322341-0",
      url: "/49720294/auportal_sp_easygame_top_overlay_320x50",
      size: "[320, 50]"
    },
    rectangle: {
      id: "1681812246209-0",
      url: "/49720294/auportal_sp_easygame_top_3_rectangle",
      size: "[[300, 250], [336, 280]]"
    },
    rectMiddle: {
      id: "1681812404902-0",
      url: "/49720294/auportal_sp_easygame_top_2_rectangle",
      size: "[[336, 280], [300, 250]]"
    },
    infeed: 93178,
    banner: {
      id: "1681812009443-0",
      url: "/49720294/auportal_sp_easygame_top_1_320x100",
      size: "[320, 100]"
    },
    ranking: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196100,
      banner: 142100
    },
    list: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196099,
      banner: 142100
    }
  },
  free: {
    fixed: {
      id: "1681812322341-0",
      url: "/49720294/auportal_sp_easygame_top_overlay_320x50",
      size: "[320, 50]"
    },
    rectangle: {
      id: "1681812246209-0",
      url: "/49720294/auportal_sp_easygame_top_3_rectangle",
      size: "[[300, 250], [336, 280]]"
    },
    rectMiddle: {
      id: "1681812404902-0",
      url: "/49720294/auportal_sp_easygame_top_2_rectangle",
      size: "[[336, 280], [300, 250]]"
    },
    infeed: 93178,
    banner: {
      id: "1681812009443-0",
      url: "/49720294/auportal_sp_easygame_top_1_320x100",
      size: "[320, 100]"
    },
    ranking: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196100,
      banner: 142100
    },
    list: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196099,
      banner: 142100
    }
  },
  paid: {
    fixed: {
      id: "1681812322341-0",
      url: "/49720294/auportal_sp_easygame_top_overlay_320x50",
      size: "[320, 50]"
    },
    rectangle: {
      id: "1681812246209-0",
      url: "/49720294/auportal_sp_easygame_top_3_rectangle",
      size: "[[300, 250], [336, 280]]"
    },
    rectMiddle: {
      id: "1681812404902-0",
      url: "/49720294/auportal_sp_easygame_top_2_rectangle",
      size: "[[336, 280], [300, 250]]"
    },
    infeed: 93178,
    banner: {
      id: "1681812009443-0",
      url: "/49720294/auportal_sp_easygame_top_1_320x100",
      size: "[320, 100]"
    },
    ranking: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196100,
      banner: 142100
    },
    list: {
      fixed: {
        id: "1681812322341-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_overlay_320x50",
        size: "[320, 50]"
      },
      rectangle: {
        id: "1681812079443-0",
        url: "/49720294/auportal_sp_easygame_top_ranking_rectangle",
        size: "[[336, 280], [300, 250]]"
      },
      rectMiddle: 142103,
      infeed: 196099,
      banner: 142100
    }
  }
}

export const microroadAdId = "b867bdb26262953dda228997b1b85b11"

export const distributionTitle = _distributionTitle[distribution]

export const google = env === "production" ? _google.production[distribution] : _google.staging[distribution]
export const repro = env === "production" ? _repro[env] : _repro.staging

export * from "./sentry"
