import React from "react"
import { createGlobalStyle } from "styled-components"
import { Reset } from "styled-reset"

import { color } from "~/libs/style"

const Global = createGlobalStyle`
  body {
    font-family: sans-serif;
    color: ${color.inkBlack};
    background-color: ${color.white};
    text-size-adjust: 100%;
    * {
        word-break: break-word;
    }
  }

  html, body {
    height: 100%;
  }

  input, textarea {
    font-family: sans-serif;
  }

  button {
    font-weight: normal;
    appearance: none;
  }

  a {
    color: ${color.inkBlack};
    text-decoration: none;
    &:link,
    &:hover,
    &:active {
      color: ${color.inkBlack};
    }
    &:visited {
      color: ${color.gray};
    }
  }
`

export const GlobalStyle = () => (
  <>
    <Reset />
    <Global />
  </>
)
