import fetch from "node-fetch"
import qs from "querystring"

import { IFetcher } from "~/repository/IFetcher"

export class Fetcher implements IFetcher {
  cmsEndpoint = ""
  cmsApiKey = ""
  constructor({ cmsEndpoint, cmsApiKey }: { cmsEndpoint: string; cmsApiKey: string }) {
    this.cmsEndpoint = cmsEndpoint
    this.cmsApiKey = cmsApiKey
  }
  async get<T = Record<string, unknown>>({
    endpoint,
    parameter
  }: {
    endpoint: string
    parameter: qs.ParsedUrlQueryInput
  }) {
    const query = qs.stringify(parameter)
    const res = await fetch(`${this.cmsEndpoint}${endpoint}?${query}`, {
      headers: {
        "X-MICROCMS-API-KEY": this.cmsApiKey
      }
    })
    if (res.status === 404) {
      return {} as T
    }
    return (await res.json()) as T
  }
}
