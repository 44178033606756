import { CommonSchema } from "~/types/MicroCms"

import { IFetcher } from "./IFetcher"

export type Experiment = {
  fieldId: "optimizeExperiment"
  testId: string
  neta: string
}

export type Config = CommonSchema & {
  experiments: Experiment[] | null
}

export class ConfigRepository {
  constructor(private fetcher: IFetcher) {}
  public async findAll() {
    return await this.fetcher.get<Config>({
      endpoint: "/config"
    })
  }
}
